* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  max-height: 100vh;
  width: 100%;
  height: 100%;
}

a {
  color: black !important;
  text-decoration: none !important;
}

input {
  width: 100%;
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
  color: black;
}

input:focus,
input:active,
input:focus-visible {
  outline: none !important;
  outline-width: 0px !important;
  border: 0px solid !important;
}

.inputContainer {
  margin: 8;
  display: flex;
  align-items: center;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  padding: 4px;
}

.inputDarkMode {
  color: #ffffff;
  background-color: #424242;
}

.inputError {
  border: 1px solid red !important;
}

textarea {
  width: 100%;
  outline: none;
  outline-width: 0px;
  border: 0px solid;
  color: black;
  padding: 8px;
}

textarea:focus,
textarea:active,
textarea:focus-visible {
  outline: none;
  outline-width: 0px;
  border: 0px solid;
}

.iagScroll {
  overflow: auto;
}

.iagScroll::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.iagScroll::-webkit-scrollbar-button,
.iagScroll::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}

.iagScroll::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.iagScroll::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}

.iagScroll::-webkit-scrollbar-track {
  background: transparent;
}

/* carousel */
.react-multiple-carousel__arrow {
  background: rgba(0, 0, 0, 0.14);
  color: white;
}

.react-multiple-carousel__arrow--left {
  display: none;
}

.react-multiple-carousel__arrow--right {
  display: none;
}

.carousel-container:hover>.react-multiple-carousel__arrow--left {
  display: block;
}

.carousel-container:hover>.react-multiple-carousel__arrow--right {
  display: block;
}

/* menu list */
.MuiMenu-paper {
  margin-top: 35px !important;
  max-height: 300px !important;
  overflow: overlay,
}

.MuiMenu-paper::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

.MuiMenu-paper::-webkit-scrollbar-button,
.MuiMenu-paper::-webkit-scrollbar-corner {
  background: transparent;
  cursor: pointer;
}

.MuiMenu-paper::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 9px;
  border: 1px solid transparent;
  background-clip: content-box;
}

.MuiMenu-paper::-webkit-scrollbar-thumb:hover {
  border: 0px solid transparent;
}

.MuiMenu-paper::-webkit-scrollbar-track {
  background: transparent;
}
